<template>
  <el-dialog :title="!dataForm.id ? $t('orgmanager.addArea') : $t('orgmanager.updateArea')" :close-on-click-modal="false" :modal-append-to-body="false" :lock-scroll="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
      <el-form-item :label="$t('orgmanager.areaName')" prop="name">
        <el-input v-model.trim="dataForm.name" :placeholder="$t('orgmanager.areaName')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('orgmanager.areaCode')" prop="code">
        <el-input v-model.trim="dataForm.code" :placeholder="$t('orgmanager.areaCode')" :readonly="dataForm.id === 0"></el-input>
      </el-form-item> 
      <el-form-item :label="$t('orgmanager.areaType')" prop="type"> 
       <sys-dict-select v-model="dataForm.type" dictType="org_type" ></sys-dict-select>  
      </el-form-item> 
      <!-- <el-form-item :label="$t('orgmanager.city')" prop="city"> 
        <sys-dict-select v-model="dataForm.city" dictType="org_city" ></sys-dict-select>  
      </el-form-item> -->
      <el-form-item :label="$t('orgmanager.longitudeLatitude')" prop="longitudeLatitude">
        <el-input v-model="dataForm.longitudeLatitude" :placeholder="$t('orgmanager.longitudeLatitude')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('orgmanager.parentCode')" prop="parentCode">
        <el-input v-model="dataForm.parentCode" :placeholder="$t('orgmanager.parentCode')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('orgmanager.parentArea')" prop="parentId">
        <sysOrg :form="dataForm" field="parentId" :multiple="false" :lazy="true"></sysOrg> 
      </el-form-item> 
    </el-form>
    <div class="mod-footer" v-loading="isOnSubmit">
      <el-button @click="visible = false" >{{ $t('cancel') }}</el-button>
      <el-button type="primary" :disabled="isOnSubmit" @click="dataFormSubmit()">{{ $t('confirm') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { treeDataTranslate } from '@/utils'
import { getById,saveOrUpdate } from '@/api/sys/region'
export default {
  data () {
    return {
      isOnSubmit: false,
      visible: false,
      popoverVisible: false,
      dataForm: {
        id: 0,
        updateTime: null,
        updateId: '',
        createTime: '',
        createid: '',
        parentId: null,
        parentCode: null,
        longitudeLatitude:'',
        name: '',
        code: '',
        type: '',
        pname: '', 
      },
      dataRule: {
        pname: [
          { required: true, message: this.$t('orgmanager.parentAreaNotEmpty'), trigger: 'blur' }
        ],
        code: [
          { required: true, message: this.$t('orgmanager.areaCodeNotEmpty'), trigger: 'blur' }
        ], 
        name: [
          { required: true, message: this.$t('orgmanager.areaNameNotEmpty'), trigger: 'blur' }
        ],  
        type: [
          { required: true, message: this.$t('orgmanager.typeNotEmpty'), trigger: 'blur' }
        ],
        parentId: [
          { required: true, message: this.$t('orgmanager.parentAreaNotEmpty'), trigger: 'blur' }
        ]
      },
      orgList: [],
      orgListTreeProps: {
        label: 'name',
        children: 'children'
      }
    }
  },
  computed: {
    // 计算内部元素的高度
    asideHeight: function () {
      return document.documentElement['clientHeight'] - 110 - 25
    }
  },
  methods: {
    init (id) {
      this.dataForm.id = id
      this.visible = true
      this.isOnSubmit = false
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        if (this.dataForm.id) {
        
          getById(id).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm.parentId = data.data.parentId
              this.dataForm.parentCode = data.data.parentCode
              this.dataForm.longitudeLatitude = data.data.longitudeLatitude
              this.dataForm.pname = data.data.pname
              this.dataForm.code = data.data.code
              this.dataForm.name = data.data.name
              this.dataForm.id = data.data.id
              this.dataForm.type = data.data.type 
              this.dataForm.city = data.data.city
               
            }
          })
        }
      })
    },  
    // 菜单树选中
    orgListTreeCurrentChangeHandle (data, node) {
      this.dataForm.parentId = data.id
      // this.dataForm.parentCode = data.code
      this.dataForm.pname = data.name
      this.popoverVisible = false
    },
    // 菜单树设置当前选中节点
    orgListTreeSetCurrentNode () {
      this.$refs.orgListTree.setCurrentKey(this.dataForm.parentId)
      this.dataForm.pname = (this.$refs.orgListTree.getCurrentNode() || {})['name']
    },

    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.isOnSubmit = true
        
          const data_ = {
              'id': this.dataForm.id,
              'parentId': this.dataForm.parentId,
              'parentCode': this.dataForm.parentCode,
              'longitudeLatitude':this.dataForm.longitudeLatitude,
              'code': this.dataForm.code,
              'name': this.dataForm.name,
              'pname': this.dataForm.pname,
              'type': this.dataForm.type ,
              'city': this.dataForm.city  
            }
          saveOrUpdate(data_).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: this.$t('prompt.success'),
                type: 'success',
                duration: 1000
              })
              this.visible = false
              this.$emit('refreshDataList')
            } else {
              this.$message.error(data.msg)
            }
          }).finally(() => {
            this.isOnSubmit = false
          })
        }
      })
    }
  }
}
</script>
