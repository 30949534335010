<template>
  <div class="mod-config">
    <div class="wxts_msg_search">
      <span style="margin-left: 15px" >当前用户所属区域：<span style="color: red">{{orgName}}</span> </span>
      <div class="f_t">
          <el-button-group>
            <el-button size="mini" type="success" @click="getDataList()" :disabled="dataListLoading">{{ $t('orgmanager.refresh') }}</el-button>
            <el-button size="mini" v-if="isAuth('sys:org')" type="primary" @click="addOrUpdateHandle()">{{ $t('add') }}</el-button>
          </el-button-group>
        </div>
    </div>
    <el-table :data="dataList" border lazy :load="load"  v-loading="dataListLoading"
              row-key="id"
              :key="tableKey"
              stripe :tree-props="{ hasChildren: 'isleaf'}" style="width: 100%;">
      <table-tree-column
        prop="name"
        header-align="center"
        treeKey="id"
        width="300"
        :label="$t('orgmanager.areaName')">
      </table-tree-column>
      <el-table-column
        prop="code"
        header-align="center"
        align="center"
        :label="$t('orgmanager.areaCode')">
      </el-table-column>
     
      <el-table-column
        prop="pname"
        header-align="center"
        align="center"
        :label="$t('orgmanager.parentAreaName')">
      </el-table-column>
<!--      <el-table-column-->
<!--        prop="parentId"-->
<!--        header-align="center"-->
<!--        align="center"-->
<!--        :label="$t('orgmanager.parentAreaCode')">-->
<!--      </el-table-column>-->

      <el-table-column
          prop="parentCode"
          header-align="center"
          align="center"
          :label="$t('orgmanager.parentCode')">
      </el-table-column>

      <el-table-column
      v-if="isAuth('sys:region') "
        header-align="center"
        align="center"
        :label="$t('handle')">
        <template slot-scope="scope">
          <el-button type="text" size="small" class="update-button"  @click="addOrUpdateHandle(scope.row.id)">{{ $t('update') }}
          </el-button>
          <el-button type="text" size="small" class="del-button"  
                     @click="deleteHandle(scope.row.id)">{{ $t('delete') }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>

  </div>
</template>

<script>
import TableTreeColumn from '@/components/table-tree-column' 
import AddOrUpdate from './add-or-update'

import { getList , deleteOrg } from '@/api/sys/region'
export default {
    data () {
      return {
        tableKey: new Date().toString(), 
        dataList: [],
        dataListLoading: false,
        addOrUpdateVisible: false
      }
    },
    computed: {
      regionId: {
        get() {
          return this.$store.state.user.regionId;
        }
      },
      orgName:{
        get(){
          return  this.$store.state.user.orgName;
        }
      },
      regionCode: {
        get() {
          return this.$store.state.user.regionCode;
        } 
      },
      gridId: {
        get() {
          return this.$store.state.user.gridId;
        } 
      },
      gridCode: {
        get() {
          return this.$store.state.user.gridCode;
        } 
      },
      gridIds: {
        get() {
          return this.$store.state.user.gridIds;
        } 
      },
      gridCodes: {
        get() {
          return this.$store.state.user.gridCodes;
        } 
      },
    },
    components: {
      TableTreeColumn,
      AddOrUpdate,
    },
    created () {
      this.getDataList()
    },
    methods: {
      // 获取数据列表
      getDataList () {
        this.dataListLoading = true
        this.getListByParent(this.$store.state.user.regionId, (dataList) => {
          this.dataList = dataList
          this.dataListLoading = false
        })
      },
      // 新增 / 修改
      addOrUpdateHandle (id) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(id)
        })
      },
      // 删除
      deleteHandle (id) {
        this.$confirm(this.$t('orgmanager.confirmDelete'), this.$t('prompt.title'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning'
        }).then(() => {
          deleteOrg(id).then(({data}) => {
            if (data && data.data != 1) {
              this.$message({
                message: this.$t('prompt.success'),
                type: 'success',
                duration: 1500
              })
              this.getDataList()
            } else {
              this.$message.error("请先删除下级区划")
            }
          }).finally(() => {
            this.tableKey = new Date().toString()
          })
        }).catch(() => {
        })
      },

      // 列表懒加载
      load (tree, treeNode, resolve) {
        this.getListByParent(tree.id, (dataList) => {
          resolve(dataList)
        })
      },
      /**
       * 根据父级ID获取下一级
       * @param pid
       * @param cb 回调函数
       */
      getListByParent (pid, cb) {
        
        getList(pid).then(({ data }) => {
          if (data && data.code === 0) {
            // data.data.forEach(t => {
            //   t.hasChildren = t.isleaf
            // })
            cb(data.data)
          }
        })
      }
    }
  }
</script>
